import React, { ReactNode } from "react";
import { cn, DataObserveKey, Divider, useUniqueId, VisualComponent } from "@siteimprove/fancylib";
import { BaseDialog } from "../base-dialog/base-dialog";
import { DialogHeader, DialogHeaderProps } from "../dialog-header/dialog-header";
import * as scss from "./side-panel.scss";

type SidePanelProps = {
	/** Indicates whether the side panel is shown or not */
	shown: boolean;
	/** Content of the side panel */
	children: ReactNode;
	/** Callback for when the close button is clicked */
	onClose: () => void;
	/** Callback for when the side panel is unmounted */
	onUnmount?: () => void;
} & VisualComponent &
	DialogHeaderProps &
	DataObserveKey;

export function SidePanel(props: SidePanelProps): JSX.Element {
	const {
		shown,
		headerTitle,
		children,
		onClose,
		onUnmount,
		headerIcon,
		headerTitleTooltipText,
		headerTitleTooltipPlacement,
		className,
		style,
	} = props;

	const id = useUniqueId("sidepanel");

	return (
		<BaseDialog
			data-component="side-panel"
			data-observe-key={props["data-observe-key"]}
			className={cn(className, scss.sidePanel, shown ? scss.slidein : scss.slideout)!}
			style={style}
			onClose={onClose}
			onUnmount={onUnmount}
			shown={shown}
			aria-labelledby={id}
		>
			<DialogHeader
				id={id}
				headerTitle={headerTitle}
				headerTitleTooltipText={headerTitleTooltipText}
				headerTitleTooltipPlacement={headerTitleTooltipPlacement}
				onClose={onClose}
				headerIcon={headerIcon}
			/>
			{children}
		</BaseDialog>
	);
}

export interface SidePanelFooterProps {
	children: React.ReactNode;
}

function SidePanelFooter({ children }: SidePanelFooterProps): JSX.Element {
	return (
		<div className={scss.sidepanelFooter}>
			<Divider />
			{children}
		</div>
	);
}

SidePanel.Footer = SidePanelFooter;
