import React, { useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Content, Paragraph, TextContainer, Ul, Button } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { DialogDropzone } from "../dropzone/dropzone";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { ActionBar } from "../../actions-and-controls/action-bar/action-bar";
import { SidePanel } from "./side-panel";

export const Meta: DocPageMeta = {
	category: "Overlay",
	title: "Side panel",
	notepad: "https://hackmd.io/wytGK0XCRueizuaRSrXLSA",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Side panel"
			subTitle="Enables focused interaction with specific tasks or information without losing sight of the main content."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={SidePanel} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					<Code>SidePanel</Code> is a a container element that overlays the main content area,
					typically anchored to the right side of the screen. It is designed to provide additional
					context, tools, or actions related to the current view. It includes a header with a close
					button, the main content area, and an optional footer. The Side Panel consists of:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Container</b>: The overall panel structure.
						</>,
						<>
							<b>Header</b>:{" "}
							<Ul
								items={[
									<>
										Title with optional <a href="#header-with-tooltip">tooltip</a> for clarity
									</>,
									"Clear close button for immediate dismissal",
								]}
							/>
						</>,
						<>
							<b>Content area</b>: Flexible space for the panel's main purpose (forms, details,
							etc.).
						</>,
						<>
							<b>Footer</b> (optional): Fixed area for actions.
						</>,
					]}
				/>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					<b>Use Cases</b>: Displaying forms, detailed information, or configuration options.
				</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:
				</Paragraph>
				<Ul
					items={[
						"Side panels should enhance, not interrupt, the main experience.",
						"Keep some of the main page content visible for context.",
					]}
				/>
				<Example fn={BasicUsage} />
				<Header.H3>Header with tooltip</Header.H3>
				<Paragraph>
					Adds a tooltip to the header title for additional context or instructions.
				</Paragraph>
				<Paragraph>
					<b>Use Cases</b>: When the header title needs clarification.
				</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:
				</Paragraph>
				<Ul
					items={[
						<>
							{" "}
							To add a tooltip to the header you must use the <Code>
								headerTitleTooltipText
							</Code>{" "}
							prop to ensure proper accessibility. Do not add the{" "}
							<GatsbyLink to="/lib/components/Overlay/Tooltip">Tooltip component</GatsbyLink> to the{" "}
							<Code>headerTitle</Code> prop.
						</>,
						<>
							If you need to change the placement of the tooltip you can use the{" "}
							<Code>headerTitleTooltipPlacement</Code> prop.
						</>,
					]}
				/>

				<Example fn={TooltipHeaderExample} />
				<Header.H3>Footer</Header.H3>
				<Paragraph>
					Includes a sticky footer within a Side panel, often used for action buttons.
				</Paragraph>
				<Paragraph>
					<b>Use Cases</b>: Presenting actions (e.g., "Save," "Cancel") to confirm choices, submit
					information, or navigate steps.
				</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:
				</Paragraph>
				<Ul
					items={[
						<>
							Side panels can optionally contain a (sticky) footer. This section is intended for
							information and actions that relate to the <Code>SidePanel</Code> as a whole. In most
							cases, that will be the{" "}
							<GatsbyLink to="/lab/components/Actions and controls/Action Bar">
								Action Bar
							</GatsbyLink>{" "}
							component, which provides users with a set of actions related to the completion of a
							task.
						</>,
						<>
							Add a footer by placing a <Code>SidePanel.Footer</Code> component inside a{" "}
							<Code>SidePanel</Code> as its last child.
						</>,
					]}
				/>

				<Example fn={FooterExample} />
				<Header.H3>Dropzone</Header.H3>
				<Paragraph>
					Controls where the <Code>SidePanel</Code> is rendered in the DOM.
				</Paragraph>
				<Paragraph>
					<b>Use Cases</b>: When you need control over the <Code>SidePanel</Code>'s placement within
					the page structure.
				</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:
				</Paragraph>
				<Ul
					items={[
						<>By default, side-panel is appended to the DOM in the bottom of {"<body>"}.</>,
						<>
							<Code>DialogDropzone</Code> lets you to control where in the DOM the side-panel is
							rendered, allowing it to inherit styles, if you don't use a global stylesheet.
						</>,
					]}
				/>

				<Example fn={DropzoneExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={SidePanel}
					initialProps={({ setState }) => ({
						shown: false,
						headerTitle: "Header text",
						onClose: () => setState({ shown: false }),
						children: (
							<p>
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro nemo repellendus
								obcaecati praesentium illo, voluptate fugiat quisquam vitae nostrum modi earum eos
								nesciunt at, placeat sint vel quas quae aliquid.
							</p>
						),
					})}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>SidePanel</Code> to
					</Paragraph>
					<Ul
						items={[
							"Display detailed information.",
							"Provide navigation options.",
							"Offer quick actions or tools.",
							"Offer contextual assistance or instructions.",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>SidePanel</Code> typically includes the following elements:
					</Paragraph>
					<Ul
						items={[
							<>
								<b>
									<GatsbyLink to="/lab/components/Tables and lists/Table">Table</GatsbyLink> /{" "}
									<GatsbyLink to="/lab/components/Tables and lists/List table">
										List table
									</GatsbyLink>{" "}
									details
								</b>
								: When a user selects an item in a table or list and requires access to more
								comprehensive information without navigating away.
							</>,
							<>
								<b>Task-specific actions</b>: When specific actions or tools are needed for a
								particular item or task, keeping the user's focus within the same view.
							</>,
							<>
								<b>Configuration tools</b>: Offering settings or customization options for a
								particular element or feature without disrupting their current workflow.
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>SidePanel</Code> to existing components for visual
								consistency.
							</>,
							<>
								Only include information or actions directly relevant to the current task. Avoid
								cluttering the side panel.
							</>,
							<>
								Dim the main content to create a visual hierarchy. This helps users distinguish the
								side panel from the main content and guides the user's eye.
							</>,
							<>
								Favor a vertical layout with clear sections and headings. Use collapsible elements
								(like accordions) to manage longer content without horizontal overflow.
							</>,
						]}
					/>
					<Header.H4>Interaction:</Header.H4>
					<Ul
						items={[
							<>
								Make it obvious how to open and close the side panel. Use intuitive icons, labels
								(e.g., button click).
							</>,
							<>
								The side panel should partially overlay the main content, maintaining context and
								preventing the user from feeling disoriented.
							</>,
							<>
								Ensure a smooth, non-jarring transition as the side panel enters and exits the
								viewport.
							</>,
							<>
								Offer multiple ways to dismiss the side panel:
								<Ul
									items={[
										"A prominent close button (usually in the top-right corner)",
										"Clicking outside the panel",
										"Pressing the Escape key (keyboard accessibility)",
									]}
								/>
							</>,
							<>
								If the content exceeds the available height, implement smooth scrolling within the
								side panel. Avoiding horizontal scrolling.
							</>,
							<>
								Ensure the side panel adapts gracefully to smaller screens by leveraging{" "}
								<GatsbyLink to="/lib/components/Structure/Grid and Grid.Section">
									Grid and Grid.Section
								</GatsbyLink>{" "}
								Consider using a different layout or collapsing non-essential elements on mobile
								devices.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>

				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"The content is critical to the primary task flow and should not be hidden behind a panel.",
							<>
								The content demands full-screen display. A{" "}
								<GatsbyLink to="/lab/components/Overlay/Modal">Modal</GatsbyLink> or separate page
								may be more appropriate.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							<>
								Ensure sufficient color contrast, clear focus states, and intuitive interactions.
							</>,
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Keep text concise and to the point. Avoid long sentences and paragraphs. Use bullet points and short phrases whenever possible.",
							"Use clear headings and visual hierarchy.",
							"Ensure that the text in the side panel can be easily localized.",
							"Write labels and instructions that are action-oriented and guide the user towards a specific goal. ",
							"Ensure that all text is relevant to the content or task presented in the side panel.",
							"Maintain consistency in tone, voice, and terminology across all side panels.",
						]}
					/>
					<Header.H4>Content to avoid:</Header.H4>
					<Ul
						items={[
							"Content that is not directly relevant to the current context.",
							"Overly detailed or complex information.",
							"Marketing or promotional content unless it is highly targeted and relevant to the user's current task.",
							"Avoid cramming too many unrelated topics into a single side panel.",
							"Avoid jargon and technical terms that users may not be familiar with.",
							"Avoid generic or vague language.",
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

/**
 * @click button
 * @visualDiffSelector dialog
 * */
const BasicUsage = () => {
	const [showSidePanel, setShowSidePanel] = useState(false);

	return (
		<>
			<Button aria-haspopup="dialog" onClick={() => setShowSidePanel(true)}>
				Trigger side panel
			</Button>
			<SidePanel
				shown={showSidePanel}
				headerTitle="Header text"
				onClose={() => setShowSidePanel(false)}
			>
				<Content>
					<Paragraph>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro nemo repellendus
						obcaecati praesentium illo, voluptate fugiat quisquam vitae nostrum modi earum eos
						nesciunt at, placeat sint vel quas quae aliquid.
					</Paragraph>
				</Content>
			</SidePanel>
		</>
	);
};

/**
 * @click button
 * @visualDiffSelector dialog
 * */
const TooltipHeaderExample = () => {
	const [showSidePanel, setShowSidePanel] = useState(false);

	return (
		<>
			<Button aria-haspopup="dialog" onClick={() => setShowSidePanel(true)}>
				Trigger side panel
			</Button>
			<SidePanel
				shown={showSidePanel}
				headerTitle="Header text with tooltip"
				headerTitleTooltipText="Lorem ipsum dolor sit amet consectetur adipisicing elit."
				headerTitleTooltipPlacement="bottom"
				onClose={() => setShowSidePanel(false)}
			>
				<Content>
					<Paragraph>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro nemo repellendus
						obcaecati praesentium illo, voluptate fugiat quisquam vitae nostrum modi earum eos
						nesciunt at, placeat sint vel quas quae aliquid.
					</Paragraph>
				</Content>
			</SidePanel>
		</>
	);
};

/**
 * @click button
 * @visualDiffSelector dialog
 * */
const FooterExample = () => {
	const [showSidePanel, setShowSidePanel] = useState(false);

	return (
		<>
			<Button aria-haspopup="dialog" onClick={() => setShowSidePanel(true)}>
				Trigger side panel
			</Button>
			<SidePanel
				shown={showSidePanel}
				headerTitle="Header text"
				onClose={() => setShowSidePanel(false)}
			>
				<Content>
					<Paragraph>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro nemo repellendus
						obcaecati praesentium illo, voluptate fugiat quisquam vitae nostrum modi earum eos
						nesciunt at, placeat sint vel quas quae aliquid.
					</Paragraph>
				</Content>
				<SidePanel.Footer>
					<ActionBar
						primary={{ children: "Confirm", onClick: () => setShowSidePanel(false) }}
						cancel={{ children: "Cancel", onClick: () => setShowSidePanel(false) }}
					/>
				</SidePanel.Footer>
			</SidePanel>
		</>
	);
};

/** @ignore [visual] */
const DropzoneExample = () => <DialogDropzone />;
